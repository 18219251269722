const service = { name: 'kickertechWidgetRendererService' };

class KickertechWidgetRendererService {
  static $inject = ['$window'];

  constructor($window) {
    this.$window = $window;
  }

  render({ type, target, params, onLoad }) {
    const widget = this.$window.document.createElement(type);

    for (const key in params) {
      if (params.hasOwnProperty(key)){
        widget.setAttribute(key, params[key]);
      }
    }

    target.appendChild(widget);
    onLoad();
  }
}

app.service(service.name, KickertechWidgetRendererService);
