import '../../service/domain/brand.js';
import '../../service/domain/system.js';
import { filter } from 'rxjs/operators';
(function () {
  const component = { name: 'lindaSitemap' };

  controller.$inject = ['$scope', 'brand', 'system$'];

  function controller($scope, _brand, _system$) {
    $scope.preloader = false;
    $scope.providerList = [];

    $scope.preloader = true;

    function getCollection() {
      _brand
        .collection()
        .then(({ result }) => {
          $scope.providerList = result;
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    getCollection();

    const subscription = _system$
      .pipe(filter((message) => message.action === 'statusChanged'))
      .subscribe(() => {
        getCollection();
      });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
