import '../../service/domain/game.js';
import '../../service/domain/user.js';
import '../../service/domain/state.js';
import '../../service/analytics/analytics.js';
import '../../service/domain/favorites.js';
import '../../service/configs/ws-events.js';
import '../../service/rx/ws$.js';
import '../../service/rx/system$.js';
import '../../service/configs/analytic-events.js';

import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaGamePageMobile' };

  controller.$inject = [
    '$scope',
    '$state',
    '$transitions',
    'game',
    'user',
    'state',
    'analytics',
    'favorites',
    'ws$',
    'wsEvents',
    'system$',
    'analyticsEvents',
  ];
  function controller(
    $scope,
    $state,
    $transitions,
    _game,
    _user,
    _state,
    analytics,
    _favorites,
    _ws$,
    _wsEvents,
    _system$,
    _analyticsEvents,
  ) {
    let subscription;

    $scope.preloader = false;
    $scope.game = {};
    $scope.mode = $state.params.mode;
    $scope.alias = $state.params.name;

    let lastBalance = _user.profile.balance;
    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.balanceUpdate))
      .subscribe((message) => {
        const newBalance = parseFloat(message.data.balance);
        const betAmount = Math.ceil((lastBalance - newBalance) * 100) / 100;
        lastBalance = newBalance;
      });

    $scope.toggleFavourites = () => {
      _favorites.toggle($scope.game);
    };

    $scope.sendOpenReg = (area) => {
      analytics.send(_analyticsEvents.createAccountButton, `game_page_${area}`);
    };

    const authorizationSubscription = _system$
      .pipe(filter((message) => message.action === 'login'))
      .subscribe(() => {
        getGame($scope.alias);
        _state.goto('realGame', { name: $scope.alias });
      });

    getGame($scope.alias);
    listenFavorites();

    const routerListenerUnsubscribe = $transitions.onSuccess({}, function (transition) {
      if (transition.from() === transition.to() && transition.params().name !== $scope.alias) {
        $scope.alias = transition.params().name;
        getGame($scope.alias);
      }
      $scope.mode = transition.params().mode;
    });

    function listenFavorites() {
      subscription = _favorites.collection().subscribe((games) => {
        if (_favorites.shouldSetFavorite(games, $scope.game)) {
          $scope.game.favorites = true;
        }

        if (_favorites.shouldRemoveFavorite(games, $scope.game)) {
          $scope.game.favorites = false;
        }
      });
    }

    function getGame(name) {
      $scope.preloader = true;
      _game.item({ name: name }).then(
        (answer) => {
          $scope.game = answer.result;
          $scope.mode = $state.params.mode;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.mode = $state.params.mode;
          $scope.preloader = false;
        },
      );
    }

    this.$onDestroy = () => {
      routerListenerUnsubscribe();
      subscription?.unsubscribe();
      authorizationSubscription?.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      mode: '<',
    },
  });
})();
