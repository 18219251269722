import '../service/license-icons/regional-icon.js'

const directive = { name: 'licenseIcon' };

(function () {
  'use strict';

  app.directive(directive.name, [
    'regionalIcon',
    function (_regionalIcon) {
      function link(scope, element) {
        const license = $_CDN.licenseConfig;
        switch (license.name) {
          case 'curacao':
          case 'anjouan':
          default:
            _regionalIcon.init(element, license);
        }
      }

      return {
        restrict: 'A',
        link,
      };
    },
  ]);
})();
