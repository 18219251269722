import '../../service/domain/user.js';
import '../../service/domain/dynamic-form.js';
import '../../service/helper.js';
import '../../service/domain/system.js';
import '../../service/domain/account.js';
import '../../service/domain/state.js';
import '../../service/configs/config.js';
import '../../service/domain/modal.js';
import '../../service/rx/ws$.js';
import '../../service/configs/ws-events.js';
import '../../service/domain/survey.js';
import { filter } from 'rxjs/operators';
// Aliases for mga: profile_pep, accept_terms_and_conditions, accept_migration_romix_to_maltix
// Aliases for de: limits_deposit_day, limits_deposit_week, limits_deposit_month, accept_terms_and_conditions, profile_birth_place, profile_nationality, profile_birth_country
// Aliases for sw: limits_deposit_day_sweden, limits_deposit_week_sweden, limits_deposit_month_sweden, accept_deposit_limit_change;

// formDataStructure:
// formData = {
//   accept_terms_and_conditions: true, // for accept_terms_and_conditions alias
//   accept_migration_gdpr: true,       // for accept_migration_gdpr alias
//   accept_migration_romix_to_maltix: true    // for accept_migration_romix_to_maltix alias

//   update: {
//     pep: true,                       // for profile_pep alias
//     birth_place: false,               // for profile_birth_place alias
//     birth_name: '',                   // for profile_birth_country alias
//     birth_country: '',                // for profile_birth_country alias
//     nationality: '',                  // for profile_nationality alias
//   },
//   update_limit: {
//     amount: '',
//     type: '',
//     group: 'deposit',
//   },
//   update_multi_limits: [
//     {
//       type: 'day',
//       amount: 1000,
//       group: 'deposit',
//     },                                // for limits_deposit_day alias
//     {
//       type: 'week',
//       amount: 1000,
//       group: 'deposit',
//     },                                // for limits_deposit_week alias
//     {
//       type: 'month',
//       amount: 1000,
//       group: 'deposit',
//     },                                // for limits_deposit_month alias
//   ],
// };
// !!! Each filed is optional, it means that formData can be like this:
// {
//   accept_terms_and_conditions: true,
//   update: {
//     pep: true,
//   }
// }

(function () {
  'use strict';

  const component = { name: 'lindaMissedDataForm' };

  controller.$inject = ['$scope', 'user', 'dynamicForm', 'helper', 'system', 'account', 'state', 'config', 'modal', 'ws$', 'wsEvents', 'survey', '$timeout'];

  function controller($scope, _user, _dynamicForm, _helper, _system, _account, _state, _config, _modal, _ws$, _wsEvents, _survey, $timeout) {
    let missedFields = _user.profile.requiredFields || [];
    let stateChangeAccess = false;

    $scope.preloader = false;
    $scope.birth_countries = [];
    $scope.nationalities = [];

    const targetFields = ['limits_deposit_day_sweden', 'limits_deposit_week_sweden', 'limits_deposit_month_sweden', 'accept_terms_and_conditions'];

    $scope.hasMissedFields = missedFields.some(item => targetFields.includes(item));
    $scope.hasMandatorySurvey = missedFields.includes('mandatory_survey');

    $scope.formData = _dynamicForm.getFormData();
    $scope.missedFields = convertMissedFields();

    // for test uncomment below
    // $scope.missedFields = {
    //   profile_pep: true,
    //   accept_terms_and_conditions: true,
    // }

    getNationalities();

    $scope.cancelDepositLimitChange = (form) => {
      $scope.formData.update_multi_limits = [
        {
          type: 'day',
          amount: _user.profile.limits.deposit.day.amount,
          group: 'deposit',
        },
        {
          type: 'week',
          amount: _user.profile.limits.deposit.week.amount,
          group: 'deposit',
        },
        {
          type: 'month',
          amount: _user.profile.limits.deposit.month.amount,
          group: 'deposit',
        },
      ];

      $scope.submit(form);
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;

      _account
        .missedFields($scope.formData)
        .then(() => {
          $scope.hasMissedFields = false;
          if($scope.hasMandatorySurvey === false) {
            goHome();
          }
          if (_config.jurisdiction === 'sweden') {
            const swedenLimits = [
              'limits_deposit_day_sweden',
              'limits_deposit_week_sweden',
              'limits_deposit_month_sweden',
            ];
            if (
              swedenLimits.some((el) => missedFields.includes(el)) &&
              !missedFields.includes('accept_deposit_limit_change')
            ) {
              _state.goto('deposit');
            }
          }

          $timeout(() => {
            form.$setSuccessful();
          }, 0);

        })
        .catch(() => {})
        .finally(() => ($scope.preloader = false));
    });

    this.$onInit = () => {
      if (!_user.profile.requiredFields?.length) {
        // for test comment below
        goHome();
      }
    };

    $scope.$on('$stateChangeStart', (event) => {
      if (!stateChangeAccess && _user.status) {
        event.preventDefault();
      }
    });

    $scope.cancel = () => {
      _modal
        .open('missed-data-cancellation')
        .then(() => goHome())
        .catch(() => {});
    };

    $scope.logout = () => {
      _account
        .logout()
        .then(() => goHome())
        .catch(() => {});
    };

    _ws$.pipe(filter(({ event }) => event === _wsEvents.surveyUpdated)).subscribe(() => {

      $scope.preloader = true;

      _account.info({ nocache: true })
        .then(() => {
          missedFields = _user.profile.requiredFields;
          if (!missedFields.length) {
            goHome();
          }
        })
        .finally(() => {
          $scope.preloader = false;
        });
    });

    function getNationalities() {
      if ($scope.missedFields.profile_nationality || $scope.missedFields.profile_birth_place) {
        $scope.preloader = true;

        _system
          .nationality()
          .then(({ result }) => {
            $scope.nationalities = [];
            $scope.birth_countries = [];

            result.forEach(({ nationality, alpha_2_code, en_short_name }) => {
              $scope.nationalities.push({
                name: nationality,
                code: alpha_2_code,
              });
              $scope.birth_countries.push({
                name: en_short_name,
                code: alpha_2_code,
              });
            });
          })
          .catch(() => {})
          .finally(() => ($scope.preloader = false));
      }
    }

    function convertMissedFields() {
      return missedFields?.reduce((fieldsObj, field) => {
        fieldsObj[field] = true;
        return fieldsObj;
      }, {});
    }

    function goHome() {
      stateChangeAccess = true;
      _state.goto('home');
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
