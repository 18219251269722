import { Subject } from 'rxjs'
import { take, filter, takeUntil } from 'rxjs/operators'

import '../../service/configs/config.js';
import '../../service/helper.js';
import '../../service/rx/splash$.js';

export const regionalIcon = 'regionalIcon';

class RegionalIcon {
  static $inject = ['$window', 'config', 'helper', 'splash$'];

  constructor($window, config, helper, splash$) {
    this.$window = $window;
    this.config = config;
    this.helper = helper;
    this.splash$ = splash$;

    this.destroy$ = new Subject();

    this.$onDestroy = () => {
      this.destroy$.next();
      this.destroy$.unsubscribe();
    };
  }

  init(element, license) {
    const licenseCodeUppercase = license?.code.toUpperCase();
    element[0].setAttribute('id', `${license?.code}-${license?.id}`);
    element[0].setAttribute(`data-${license?.code}-seal-id`, `${license?.id}`);
    element[0].setAttribute(`data-${license?.code}-image-size`, `128`);
    element[0].setAttribute(`data-${license?.code}-image-type`, `basic-small`);

    if (typeof this.$window[licenseCodeUppercase] !== 'undefined') this.$window[licenseCodeUppercase].init();

    this.splash$
      .pipe(
        filter((message) => message === 'loaded'),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(async () => {
        this.helper.dom.addScript(`https://${license?.id}.${license?.cdnPath}`, () => {
          this.$window[licenseCodeUppercase].init();
        });
      });
  }
}

app.factory(regionalIcon, RegionalIcon)

