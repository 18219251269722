import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import './kickertech-script-loader.service.js';
import './kickertech-widget-renderer.service.js';
import '../ee/ee-sport.js';
import '../rx/system$.js';

const service = { name: 'kickertechWidgetService' };

class KickertechWidgetService {
  static $inject = ['system$', 'eeSport', 'kickertechScriptLoaderService', 'kickertechWidgetRendererService'];

  constructor(system$, eeSport, kickertechScriptLoaderService, kickertechWidgetRendererService) {
    this.system$ = system$;
    this.eeSport = eeSport;
    this.kickertechScriptLoaderService = kickertechScriptLoaderService;
    this.kickertechWidgetRendererService = kickertechWidgetRendererService;

    this.init();
  }

  kickertechLaunchCode = 'sport-tglab';
  kickertechBroadcastChannelName = 'KTSBChannel';
  userStatusChangedAction = 'statusChanged';
  kickertechOptionsSubject$ = new BehaviorSubject(null);
  receivedEventsSubject$ = new Subject();
  broadcastChannel = new BroadcastChannel(this.kickertechBroadcastChannelName);
  kickertechOptions$ = this.kickertechOptionsSubject$.asObservable();
  receivedEvents$ = this.receivedEventsSubject$.asObservable();

  init() {
    this.initKickertechOptions();
    this.initScript();
    this.interceptReceivedMessages();
  }

  initKickertechOptions() {
    this.system$
      .pipe(
        filter(({ action }) => action === this.userStatusChangedAction),
        startWith(null),
      )
      .subscribe(() => {
        this.eeSport
          .launch({ code: this.kickertechLaunchCode })
          .then((response) => this.kickertechOptionsSubject$.next(response.result.launch_options))
          .catch((error) => console.error(error));
      });
  }

  initScript() {
    this.kickertechOptions$
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe(({ host }) => this.kickertechScriptLoaderService.init(host));
  }

  initWidget({ type, target, params, onLoad }) {
      return combineLatest([ this.kickertechScriptLoaderService.isLoading$, this.kickertechOptions$ ])
        .pipe(
          filter(([ isScriptLoading, kickertechOptions ]) => isScriptLoading === false && kickertechOptions),
          take(1),
          map(([ , { host, token, ...defaultParams}]) => defaultParams),
        )
        .subscribe((defaultParams) => this.kickertechWidgetRendererService.render({
          type,
          target,
          params: {
            ...defaultParams,
            ...params,
          },
          onLoad,
        }));
  }

  interceptReceivedMessages() {
    this.broadcastChannel.onmessage = ({ data }) => {
      this.receivedEventsSubject$.next(data);

      if (data.SBLoaded) {
        this.kickertechOptionsSubject$
          .pipe()
          .subscribe(({ token }) => token ? this.logIn(token) : this.logOut());
      }
    };
  }

  sendEvent(event) {
    this.broadcastChannel.postMessage(event);
  }

  renderByPath(path) {
    this.sendEvent({ renderByPath: { path } });
  }

  reloadSB() {
    this.sendEvent({ reloadSB: true });
  }

  initSB() {
    this.sendEvent({ initSB: true });
  }

  destroySB() {
    this.sendEvent({ destroySB: true });
  }

  pageSwitch(page) {
    this.sendEvent({ pageSwitch: { page } });
  }

  toggleSearch(action) {
    this.sendEvent({ toggleSearch: { action } });
  }

  toggleBettingHistory(action) {
    this.sendEvent({ toggleBettingHistory: { action } });
  }

  toggleLeftMenu(action) {
    this.sendEvent({ toggleLeftMenu: { action } });
  }

  switchLanguage(language) {
    this.sendEvent({ switchLanguage: { language } });
  }

  logIn(token) {
    this.sendEvent({ logIn: { token } });
  }

  logOut() {
    this.sendEvent({ logOut: true });
  }

  addSelections(selections) {
    this.sendEvent({ addSelections: { selections } });
  }

  translatePath(path, language) {
    this.sendEvent({ path, language });
  }
}

app.service(service.name, KickertechWidgetService);
