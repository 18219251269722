import { BehaviorSubject } from 'rxjs';
import '../helper.js';

const service = { name: 'kickertechScriptLoaderService' };

class KickertechScriptLoaderService {
  static $inject = ['helper'];

  constructor(helper) {
    this.helper = helper;
  }

  isLoadingSubject$ = new BehaviorSubject(null);
  isLoading$ = this.isLoadingSubject$.asObservable();

  init(src) {
    if (this.isLoadingSubject$.getValue() !== null) {
      return;
    }

    this.isLoadingSubject$.next(true);

    this.helper.dom.addScript(src, () => {
      this.isLoadingSubject$.next(false);
    });
  }
}

app.service(service.name, KickertechScriptLoaderService);
