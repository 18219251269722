import '../../../service/domain/survey.js';
import '../../../service/missed-data.js';
import '../../../service/domain/state.js';
import '../../../service/rx/ws$.js';
import '../../../service/configs/ws-events.js';
import { filter } from 'rxjs/operators';

class SurveyList {
  static $inject = ['$scope', 'survey', 'missedData', 'state', 'ws$', 'wsEvents'];
  constructor($scope, _survey, missedData, _state, _ws$, _wsEvents) {
    this.missedData = missedData;
    this.state = _state;

    const getSurveyCollection = () => {
      $scope.collection = [];
      $scope.preloader = true;

      _survey.getList()
        .then((answer) => {
          $scope.collection = answer;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };

    this.$onChanges = () => getSurveyCollection();

    _ws$.pipe(filter(({ event }) => event === _wsEvents.surveyUpdated)).subscribe(() => {
      getSurveyCollection();
    });
  }
}

new Controller('surveyList', SurveyList, {});
(function () {
  [ 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSurveyList${i}`, 'surveyList');
  });
})();
