import '../../service/domain/http.js';
import '../../service/domain/user.js';
import '../../service/domain/popup.js';
import '../../service/sessions.js';
import '../../service/analytics/analytics.js';
import '../../service/configs/analytic-events.js';
import '../../service/domain/system.js';
import '../../service/domain/platform.js';

(function () {
  'use strict';

  controller.$inject = [
    'http',
    '$q',
    '$window',
    'user',
    'popup',
    'sessions',
    'analytics',
    'analyticsEvents',
    'system',
    'platform',
  ];

  function controller(_http, $q, $window, _user, _popup, _sessions, _analytics, _analyticsEvents, _system, _platform) {
    return {
      favourite: {
        toggle(name) {
          const games = ($window.localStorage.getItem('fav') || '').split(',');
          let index = games.indexOf(name);
          index < 0 ? games.push(name) : games.splice(index, 1);

          const result = games.filter((item) => {
            return item !== '';
          });

          index = games.indexOf(name);

          $window.localStorage.setItem('fav', result.join(','));
          return index >= 0;
        },

        check(name) {
          const games = ($window.localStorage.getItem('fav') || '').split(',');
          return games.indexOf(name) >= 0;
        },

        collection() {
          return ($window.localStorage.getItem('fav') || '').split(',');
        },
      },

      collection(options, cache) {
        const defer = $q.defer();
        const alias = 'gameList';
        const config = {
          params: options,
          cache: options.random !== true,
          meta: {
            type: 'api',
            alias,
          },
        };

        if (typeof cache === 'boolean') config.cache = cache;
        _platform.version().then((version) => {
          if (config.params.brand === 'ela' && version === 2) {
            config.params.brand += ',claw';
          }
          getGames();
        });

        function getGames() {
          _http.get(alias, config).then(
            (answer) => {
              const r = answer.data;
              if (config.params.brand === 'ela,claw') {
                const _result = [];
                r.result.forEach((e) => {
                  if (e.brand_code === 'ela' || e.brand_alias === 'ela') _result.push(e);
                });
                r.result.forEach((e) => {
                  if (e.brand_code === 'claw' || e.brand_alias === 'claw') _result.push(e);
                });
                r.result = _result;
              }
              defer.resolve(r);
            },
            (answer) => {
              defer.reject(answer.data);
            },
          );
        }

        return defer.promise;
      },

      recentlyPlayed(options) {
        const defer = $q.defer();
        const alias = 'gameRecentlyPlayed';
        const config = {
          params: options,
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      item(options) {
        const alias = {
          name: 'gameItem',
          param: options.name,
        };
        return _http.getData(alias, {}, options.cache);
      },

      launch(options) {
        const defer = $q.defer();
        const alias = 'gameLaunch';
        const config = {
          params: options,
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http
          .get(alias, config)
          .then(({ data }) => {
            if (options.mode === 'real') {
              const id = data.result.session_id;
              const alias = options.code;
              const launchTime = Date.now();

              _sessions.add({ id, alias, launchTime });
            }

            defer.resolve(data);
            const url = data.result.url && !/^\//.test(data.result.url) ? data.result.url : location.href;
            const { host } = new URL(url);
            _analytics.send(
              _analyticsEvents.gameLaunchSuccess,
              `${options.mode}:${options.code}:${data.system}`,
              host,
              {
                nonInteraction: true,
              },
            );
          })
          .catch(({ data }) => {
            defer.reject(data);

            _analytics.send(
              _analyticsEvents.gameLaunchFail,
              `${options.mode}:${options.code}`,
              data?.messages[0]?.code || 'UNKNOWN_ERROR',
              { nonInteraction: true },
            );
          });

        return defer.promise;
      },

      /**
       *
       * @param {Object} options
       * @param {string} options.game_session_id
       * @return {Promise}
       */
      closeGameSession(options) {
        const defer = $q.defer();
        const alias = 'gameCloseSession';
        const config = {
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http
          .post(alias, options, config)
          .then(({ data }) => {
            _sessions.remove(options.game_session_id);
            defer.resolve(data);
          })
          .catch(({ data }) => {
            defer.reject(data);
          });

        return defer.promise;
      },

      mobileLaunch(options) {
        const self = this;

        if (options.mode === 'real' && _user.status === false) {
          _popup.open({
            name: 'login',
            cb() {
              self.launch(options).then((answer) => {
                $window.location = answer.result.url;
              });
            },
          });
        } else {
          self.launch(options).then((answer) => {
            $window.location = answer.result.url;
          });
        }
      },

      check(options) {
        const defer = $q.defer();
        const alias = 'gameCheck';
        const config = {
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http.post(alias, options, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      toggleFavourites(options) {
        const defer = $q.defer();
        const alias = 'gameToggleFav';
        const config = {
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http.post(alias, options, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
      // pt only
      gameSessionInfo(data) {
        const defer = $q.defer();
        const alias = 'ptGameSessionInfo';
        const config = {
          params: data,
          meta: { alias },
        };
        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );
        return defer.promise;
      },

      search(data) {
        const defer = $q.defer();
        const alias = 'gameSearch';
        const config = {
          params: data,
          meta: { alias },
        };
        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );
        return defer.promise;
      },
    };
  }

  app.factory('game', controller);
})();
