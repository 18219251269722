import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import '../../service/kickertech/kickertech-widget.service.js';
import '../../service/domain/state.js';
import '../../service/configs/config.js';

const directive = { name: 'kickertechWidget' };

class KickertechWidgetDirective {
  static $inject = ['$location', 'state', 'config', 'kickertechWidgetService'];

  constructor($location, state, config, kickertechWidgetService) {
    this.$location = $location;
    this.state = state;
    this.config = config;
    this.kickertechWidgetService = kickertechWidgetService;
  }

  static types = Object.freeze({
    sportsbook: "kt-sb-main",
    virtualSport: "external-sb-ssp",
  });

  link(scope, element, attrs) {
    scope.preloader = true;

    const destroy$ = new Subject();
    const params = scope.$eval(attrs[directive.name]);
    const generateKickertechParams = (params) => {
      const baseParams = {
        live_path: `${this.config.currentLang}/${params?.live || 'live'}`,
        pre_path: `${this.config.currentLang}/${params?.prelive || 'sport'}`,
        ssp_path: `${this.config.currentLang}/${params?.virtual || 'virtual'}`,
        initializible: true,
      };

      return params?.pageId ? { ...baseParams, page_id: params.pageId } : baseParams;
    };
    const urlProcessing = () => {
      this.kickertechWidgetService.renderByPath(this.$location.path());

      scope.$on('$locationChangeSuccess', ($event, next, current) => {
        if (next !== current) {
          const path = new URL(next).pathname;
          this.kickertechWidgetService.renderByPath(path);
        }
      });
    };

    this.kickertechWidgetService.initWidget({
      type: KickertechWidgetDirective.types[params.type],
      target: element[0],
      params: generateKickertechParams(params),
      onLoad: () => this.kickertechWidgetService.initSB(),
    });

    this.kickertechWidgetService.receivedEvents$
      .pipe(
        takeUntil(destroy$),
      )
      .subscribe((event) => {
        if (event.SBLoaded) {
          scope.$evalAsync(() => scope.preloader = false);

          urlProcessing();
        }

        if (event.depositClickEvent) {
          this.state.goto('deposit');
        }

        if (event.loginClickEvent) {
          this.state.goToAuthorization();
        }
      });

    scope.$on('$destroy', () => {
      this.kickertechWidgetService.destroySB();
      destroy$.next();
      destroy$.unsubscribe();
    });
  }
}

app.directive(directive.name, () => {
  return {
    restrict: 'A',
    controller: KickertechWidgetDirective,
    link: function (scope, element, attrs, ctrl) {
      ctrl.link(scope, element, attrs, ctrl);
    },
  };
});
